import "./MusicLyric.css"
import youtubelogo from "../../assets/youtubelogo.png"

export default function MusicLyric({ title, author, lyric, musicKey, youtube, onBack }) {
    return (
        <div className="music-lyric">
            <button onClick={onBack}>Voltar</button>
            <h3>{title} - {musicKey}</h3>
            <h4>{author}</h4>
            <div className="youtube-link">
                <a href={youtube}>Youtube</a>
            </div>
            <p>{lyric}</p>
        </div>
    );
  }