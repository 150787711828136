export const MUSIC_DATA = [
    {
        id: 1,
        title: "Ao Único",
        author: "Aline Barros",
        level: "MEDIUM",
        musicKey: "C",
        youtube: "",
        lyric: `
C              G/B      Am
Ao único que é digno de receber
    F         C/E       Dm           G
A honra e a glória, a força e o poder
    C            C/E      F          Fm    
Ao rei eterno, imortal, invisível, mas real
    C/G        G           C
A ele, ministramos o louvor

    Am G    F              C   E/G#  
Coroamos a ti, oh, rei Jesus
    Am G    F              G
Coroamos a ti, oh, rei Jesus
    F           G/F
Adoramos o teu nome
        E     E/G#     Am
Nos rendemos aos teus pés
    Dm           G           C
Consagramos todo nosso ser a ti`
    },
    {
        id: 2,
        title: "Em Espirito, Em Verdade (Meu prazer)",
        author: "Ministério Koynonia de Louvor",
        level: "EASY",
        musicKey: "E",
        youtube: "",
        lyric: `
     E            G#m
Em espírito, em verdade
       A            E     B
Te adoramos, te adoramos
        E           G#m
Em espírito, em verdade
       A            E        B/D#
Te adoramos, te adoramos

        C#m       C#m/B
Rei dos reis e Senhor
        A     B       E      B/D#
Te entregamos nosso viver
        C#m        C#m/B
Rei dos reis e Senhor
        A     F#m      B    E/G#m
Te entregamos nosso viver

          A      F#m      B     E/G#
Pra te adorar, ó Rei dos reis
               A     F#m   B
Foi que eu nasci, ó Rei Jesus
        G#/C        C#m
Meu prazer é te louvar
        B        A
Meu prazer é estar
     B            E
Nos átrios do Senhor
        B/D#    C#m
Meu prazer é viver
    B      A
Na casa de Deus
       B      E
Onde flui o amor`
    },
    {
        id: 3,
        title: "Jesus em tua presença",
        author: "Morada",
        level: "MEDIUM",
        musicKey: "C",
        youtube: "",
        lyric: `
    C/E           F         G         Am
Jesus, em Tua presença reunimo-nos aqui
        C/E      F         G          C     G/B
Contemplamos Tua face e rendemo-nos a Ti
        Am      Em            F      G     Am
Pois um dia Tua morte trouxe vida a todos nós
        C/E         F        G           C    G/B
E nos deu completo acesso ao coração do Pai

   Am         Em      F    G     Am
O véu que separava já não separa mais
   Am              Em     F        
A luz que outrora apagada agora brilha
  G                D
E cada dia brilha mais

F         G   Am
Só pra Te adorar
     F      G     Am 
E fazer Teu nome grande
F    G        Am   G      D
E Te dar o louvor que é devido
    F   G     C
Estamos nós aqui`
    },
    {
        id: 4,
        title: "Te agradeço",
        author: "Ana Paula Valadão",
        level: "EASY",
        musicKey: "A",
        youtube: "",
        lyric: `
    A               D
Por tudo o que Tens feito
    A               E
Por tudo que Vais fazer
    A/C#              S
Por Tuas promessas e tudo que És
    A/C#           E
Eu quero Te agradecer
             D     A
Com todo meu ser

       D           A
Te agradeço meu Senhor (Te agradeço meu Senhor)
       D           A
Te agradeço meu Senhor (Te agradeço meu Senhor)

        A                     
Te agradeço por me libertar e salvar
D
Por ter morrido
                    A/C#  D
Em meu lugar, Te agradeço
              A/C#   D
Jesus, Te agradeço
          A/C#   D
Eu Te agradeço
        E     A
Te agradeço`
    },
    {
        id: 5,
        title: "O mover do Espírito (Quero que valorize)",
        author: "Armando Filho",
        level: "EASY",
        musicKey: "G",
        youtube: "",
        lyric: `
G              C               G
Quero que valorize o que você tem
           C            G
Você é um ser você é alguém
         C          D
Tão importante para Deus

G                C                G
Nada de ficar sofrendo angústia e dor
              C         G
Nesse seu complexo interior
           C                   D
Dizendo as vezes que não é ninguém
            C        D            G    G/B
Eu venho falar do valor que você tem
           C        D             G    C  D
Eu venho falar do valor que você tem

G             C
Ele está em você
            G                  C
O Espírito Santo se move em você
          G    Em      D 
Até com gemidos inexprimíveis, inexprimíveis
G                C
Daí você pode então perceber
        G                C
Que pra Ele há algo importante em você
    G               Em                  D
Por isso levante e cante, exalte ao Senhor
           Em
Você tem valor
            C                 D
O Espírito Santo se move em você
            Em
Você tem valor
            C                  D
O Espírito Santo se move em você`
    },
    {
        id: 6,
        title: "Deus me ama",
        author: "Thales Roberto",
        level: "EASY",
        musicKey: "D",
        youtube: "",
        lyric: `
D                                      G
Mesmo sendo assim, pobre pecador Deus me ama
D                                      G
Mesmo sendo falho, mesmo sem merecer Deus me ama
D                                      G
Se eu estou forte, se eu estou de pé Deus me ama
D             
Se eu estou fraco, se eu estou caído
        G
Ele não deixa de me amar

D                                       G
Sem o Seu amor, Sem o seu perdão, o que seria de mim?
D
Deus me amou tanto que entregou Seu filho
        G
Para morrer em meu lugar

D                     G
Deus me ama, e o Seu amor é tão grande incondicional
D                       C                 G
Deus me ama, e Ele está sempre de braços abertos pra mim`
    },
    {
        id: 7,
        title: "Não há Deus maior",
        author: "Comunidade Niópolis",
        level: "MEDIUM",
        musicKey: "D",
        youtube: "",
        lyric: `
D              Em
Não há Deus maior
A               D   Bm
Não há Deus melhor
                  Em    
Não há Deus tão grande
A           D       G/A
Como nosso Deus

F#      F#/A#        Bm
Criou o céu, criou a Terra
F#      F#/A#       Bm
Criou o Sol e as estrelas
Em        A   Em      A
Tudo Ele fez, tudo criou
Em      A
Tudo formou

G       A/G    D
Para o Seu louvor
G       A/G     D
Para o Seu louvor
G       A/G  F#     Bm
Para o Seu, para o Seu
Em     A       D
Para o Seu louvor`
    },
    {
        id: 8,
        title: "Coração Igual ao Teu",
        author: "Diante do Trono",
        level: "HARD",
        musicKey: "F",
        youtube: "",
        lyric: `
       
        Bb
Se Tu olhares, Senhor, 
             F/A
pra dentro de mim
        Gm          C
Nada encontrarás de bom
    Bb          C/Bb
Mas um desejo eu tenho 
   F   C/E     Dm
de ser transformado
    Gm           F/A
Preciso tanto do Teu perdão
Bb            C
Dá-me um novo coração

F           F/A           Bb
Dá-me um coração igual ao Teu, 
     Gm   C        
meu Mestre
F            F/A           Bb
Dá-me um coração igual ao Teu
    C    C/Bb        Am   Dm
Coração disposto à obedecer
    Am                 Dm
Cumprir todo o Teu querer
Gm           C             F
Dá-me um coração igual ao Teu

C/E         C           Dm
Ensina-me a amar o meu irmão
    C              
A olhar com Teus olhos
    F               F/A
Perdoar com o Teu perdão
Bb           C/Bb
Enche-me com Teu Espírito
Am           Dm
Endireita os meus caminhos
C  Bb            C     F
Ó Deus, dá-me um novo coração
Bb           C/Bb
Enche-me com Teu Espírito
Am           Dm
Endireita os meus caminhos
C  Bb            C     D
Ó Deus, dá-me um novo coração

G           G/B           C
Dá-me um coração igual ao Teu, 
     Am   D        
meu Mestre
G            G/B           C
Dá-me um coração igual ao Teu
    D    D/C        Bm   Em
Coração disposto à obedecer
    Bm                 Em
Cumprir todo o Teu querer
Am           D             G
Dá-me um coração igual ao Teu


`
    },
    {
        id: 9,
        title: "Por toda minha vida",
        author: "Voz da Verdade",
        level: "EASY",
        musicKey: "D",
        youtube: "",
        lyric: `
                 D        
Por toda a minha vida
                   Em
Ó senhor, te louvarei
                        A
Pois meu fôlego é a tua vida
                D
E nunca me cansarei

                   D
Posso ouvir a tua voz
                      Em
É mais doce do que o mel
                  A
Que me tira desta cova
                D
E me leva até o céu

                 D
Já vi fogo e terremotos
                    Em
Vento forte que passou
                 A
Já vivi tantos perigos
                    D
Mas Tua voz me acalmou

                  D
Tu dás ordem às estrelas
                    Em
E ao mar, os seus limites
                   A
Eu me sinto tão seguro
                   D
No Teu colo, oh, Altíssimo

         Bm
Não há ferrolhos
    F#m         
Nem portas 
        G       A           D        
que se fechem diante da Tua voz
         Bm         F#m
Não há doenças, nem culpa
    G         A             D
Que fiquem de pé diante de nós
          Bm      F#m
E a tempestade se acalma
    G           A           D
Na voz Daquele que tudo criou
           Bm       F#m
Pois Sua palavra é pura
    G          A           D
Escudo para os que n'Ele creem`
    },
    {
        id: 10,
        title: "Redenção",
        author: "Projeto Sola",
        level: "MEDIUM",
        musicKey: "D",
        youtube: "",
        lyric: `
Vivo hoje estou aqui
Pois Ele decidiu me amar
E então eu posso livre andar

O Seu sangue sobre mim
Me comprou, me restaurou
Sem culpa então me tornou

O passado já não mais tem poder pois novo sou
Nele vou viver, posso amar
Toda morte e o sofrer não me assustarão jamais
Pois foi Ele quem venceu em meu lugar

Jesus Cristo Rei dos reis
Nos trouxe a redenção
A esperança retornou
Graça e paz nos revelou

Tudo entregar, vou me render, aos Seus pés vou me lançar
Tudo entregar, vou me render, aos Seus pés vou me lançar
Tudo entregar, vou me render, aos Seus pés vou me lançar

O passado já não mais tem poder pois novo sou
Nele vou viver, posso amar
Toda morte e o sofrer não me assustarão jamais
Pois foi Ele quem venceu em meu lugar

Jesus Cristo Rei dos reis
Nos trouxe a redenção
A esperança retornou
Graça e paz nos revelou
`
    },
    {
        id: 11,
        title: "Maravilhosa Graça",
        author: "CG Sede",
        level: "EASY",
        musicKey: "E",
        youtube: "https://youtu.be/XjZQHVjJO8E?si=HOwVxInKt_li8sPI",
        lyric: `
Quem tem poder sobre o pecado,
o seu amor é poderoso
o rei da glória
o poderoso Deus

A terra treme com sua voz
e nos deixa maravilhados
o Rei da glória
o poderoso Deus

Maravilhosa graça
Teu amor que não falha
tomou o meu lugar
levou a minha cruz

Teu sangue me salvou
minha dívida pagou
Jesus eu canto 
porque hoje eu livre sou

És quem traz paz 
ao caos do homens
És escudo aos indefesos
o Rei da glória
o poderoso Deus

Reina em justiça 
entre as nações
A luz que brilha na escuridão
o Rei da glória
o poderoso Deus
`
    },
    {
        id: 12,
        title: "Pai Nosso",
        author: "Pedras Vivas",
        level: "EASY",
        musicKey: "B",
        youtube: "https://www.youtube.com/watch?v=NYFcTj-KmvI",
        lyric: `
Pai nosso, nos céus
Santo é o Teu nome
Teu reino buscamos
Tua vontade seja feita

Na terra como é nos céus
Deixe o céu descer
Na terra como é nos céus
Deixe o céu descer

Deixa o céu descer  (Rep.)

Teu é o reino 
Teu O poder e 
Tua é a glória pra sempre amém
(Rep.)
`
    },
    {
        id: 13,
        title: "A minha fé",
        author: "Ministério de Louvor Rio do Trono",
        level: "EASY",
        musicKey: "A",
        youtube: "https://youtu.be/Dg5CY-qXRks?si=6nDzCdb0x8JozEks",
        lyric: `
Em nada ponho a minha fé
Senão na graça de Jesus

No sacrifício remidor
No sangue do bom redentor

Cristo é a rocha em que
Firme está a minha fé
Na aflição, é senhor salvador

Se não lhe posso a face ver
Na sua graça vou viver
Em cada crise, sem falhar
Sempre hei de nele confiar
Sempre hei de nele confiar

Cristo é a rocha em que
Firme está a minha fé
Na aflição, é senhor salvador

Assim que o seu clarim soar
Irei com ele me encontrar
Me alegrarei na redenção
Com todos que no céu estão

Cristo é a rocha em que
Firme está a minha fé
Na aflição, é senhor salvador
`
    },
    {
        id: 14,
        title: "Em Teus braços é meu descanso",
        author: "Lukas Agustinho",
        level: "EASY",
        musicKey: "E",
        youtube: "https://youtu.be/Ijymk5V67WI?si=4wYtADeDUZ2onODw",
        lyric: `
Segura estou nos braços
Daquele que nunca me deixou
Seu amor perfeito sempre esteve
Repousado em mim

E se eu passar pelo vale
Acharei conforto em Teu amor
Pois eu sei que és aquele
Que me guarda, me guardas

Em Teus braços é meu descanso (REP.)
`
    },
    {
        id: 15,
        title: "Eu te bendirei",
        author: "Ronaldo Bezerra",
        level: "EASY",
        musicKey: "G",
        youtube: "https://www.youtube.com/watch?v=1IZkBNpDSQk",
        lyric: `
Eu te bendirei 
quando Tuas bênçãos eu provar
Quando teu rio me saciar, 
eu te bendirei

Eu te bendirei 
quando no deserto estiver
Quando a solidão me envolver, 
eu te bendirei

Pelas bênção recebidas 
Te dou louvor
Quando as trevas me cercarem 
cantar eu vou

Eu te bendirei ó Senhor, 
eu te bendirei
Eu te bendirei ó Senhor, 
Teu glorioso nome bendirei

Eu te bendirei 
quando a luz do Sol brilhar em mim
Quando tudo certo estiver 
Eu te bendirei

Eu te bendirei 
no caminho de aflição
Se a dor tomar meu coração 
Eu te bendirei

Pelas bênção recebidas 
Te dou louvor
Quando as trevas me cercarem 
cantar eu vou

Eu te bendirei ó Senhor, 
eu te bendirei
Eu te bendirei ó Senhor, 
Teu glorioso nome bendirei

Ó Soberano Deus, 
em Ti eu confiarei
Tu és o meu Senhor, 
Teu nome Bendirei`
    },
    {
        id: 16,
        title: "Maranata",
        author: "Ministério Avivah",
        level: "MEDIUM",
        musicKey: "D",
        youtube: "https://www.youtube.com/watch?v=iS6GXPbhCwU",
        lyric: `
Tu és a minha Luz
A minha Salvação
E a Ti me renderei

Se ao teu lado estou
Seguro em Tuas mãos
Eu nada temerei

Só em Ti confiarei
Eu nada temerei
Em frente eu irei
Pois eu sei que vivo estás
E um dia voltarás 
do céu pra nos buscar
Para sempre reinarás, aleluia 

Vem, Jesus!
Vem, Jesus!
Maranata, ora, vem, Senhor Jesus
`
    },
    {
        id: 17,
        title: "Atos 2",
        author: "Gabriela Rocha",
        level: "EASY",
        musicKey: "D",
        youtube: "https://www.youtube.com/watch?v=WWrU6LC_4ho",
        lyric: `
Nós estamos aqui, 
tão sedentos de ti
Vem, ó, Deus, vem, ó, Deus
Enche este lugar
Meu desejo é sentir 
teu poder, Teu poder

Nós estamos aqui, 
tão sedentos de ti
Vem, ó, Deus, 
vem, ó, Deus
Enche este lugar
Meu desejo é sentir 
teu poder, Teu poder

Então, vem me incendiar
Meu coração é o teu altar
Quero ouvir o som do céu
Tua glória contemplar

Te damos honra
Te damos glória
Teu é o poder
Pra sempre, amém
Oh, Deus
`
    },
    {
        id: 18,
        title: "Teu amor não falha",
        author: "Paulo Cesar Baruk",
        level: "MEDIUM",
        musicKey: "C",
        youtube: "https://youtu.be/NGB5k7pD7AM?si=tRTz8mFnjnZtBp5B",
        lyric: `
Nada vai me separar
Mesmo se eu me abalar
Teu amor não falha

Mesmo sem merecer
Tua graça se derrama sobre mim
Teu amor não falha

Tu és o mesmo pra sempre
Teu amor não muda
Se o choro dura uma noite
A alegria vem pela manhã

Se o mar se enfurecer
Eu não tenho o que temer
Porque eu sei que me amas
Teu amor não falha

Se o vento é forte 
e profundo o mar
Tua presença vem me amparar
Teu amor não falha

Difícil é o caminhar
Nunca pensei que eu fosse alcançar
Mas teu amor não falha
`
    },
    {
        id: 19,
        title: "Tu és digno de tudo",
        author: "Fernanda Ferro",
        level: "MEDIUM",
        musicKey: "C",
        youtube: "https://www.youtube.com/watch?v=N1fVZ-2qtzQ",
        lyric: `
Os santos e os anjos
Se prostram aos Teus pés
Os anciãos rendem suas coroas ao cordeiro
E a Ele cantam

                                
Tu És digno de tudo
Tu És digno de tudo
Pois tudo vem de Ti
Tudo é para Ti
Tua é a glória

Dia e noite suba a Ti nossa adoração
Dia e noite suba a Ti nossa adoração
Dia e noite suba a Ti nossa adoração
Dia e noite suba a Ti nossa adoração


Te exaltamos
Te exaltamos
Te exaltamos
Oh Senhor 
`
    },
    {
        id: 20,
        title: "Vida aos sepulcros",
        author: "Gabriela Rocha",
        level: "EASY",
        musicKey: "B",
        youtube: "https://www.youtube.com/watch?v=cegHLh3Xbl8",
        lyric: `
No mundo busquei
Mas não preencheu-me
Canções tão vazias
Tesouros que um dia
Se consumarão

Então Você veio
E me trouxe pra perto
E todo desejo
É satisfeito Em Seu amor

Não há nada, nada melhor
Não há nada, nada melhor
Não há nada, nada melhor que meu Deus

Não tenho medo
De expor minhas fraquezas
Defeitos e falhas
Não vou esconder
Um amigo encontrei 
`
    },
    {
        id: 21,
        title: "Eu navegarei",
        author: "Gabriela Rocha",
        level: "EASY",
        musicKey: "Dm",
        youtube: "https://youtu.be/nSvxVCdj_gU?si=5qa43sWzixFvnUUd",
        lyric: `
Eu navegarei
No oceano do Espírito
E ali adorarei
Ao Deus do meu amor

Espírito, Espírito
Que desce como fogo
Vem como em pentecostes
E enche-me de novo

Eu adorarei
Ao Deus da minha vida
Que me compreendeu
Sem nenhuma explicação 
`
    },
    {
        id: 22,
        title: "Pela Fé",
        author: "André Valadão",
        level: "EASY",
        musicKey: "A",
        youtube: "https://www.youtube.com/watch?v=qIOHZ1ZOXAw",
        lyric: `
Pela fé posso clamar
Pela fé posso enxergar
Pela fé posso tocar
Pela fé, pela fé

Não há muralhas
Que ficarão de pé
Diante de mim
Estou firmado
Em Cristo, pra sempre
Tenho a luz em mim

Pela fé posso mudar
Pela fé posso alcançar
Pela fé posso viver
Oh, pela fé, pela fé

Não há muralhas
Que ficarão de pé
Diante de mim
Estou firmado
Em Cristo, pra sempre
Tenho a luz em mim

Pela fé
Pela fé
Pela fé
Pela fé
`
    },
    {
        id: 23,
        title: "Quebrantado",
        author: "Vineyard + Juninho Afram",
        level: "EASY",
        musicKey: "A",
        youtube: "https://www.youtube.com/watch?v=QbnmpJo3DiI",
        lyric: `
INTRO: A E  F#m D

                A
Eu olho para a cruz
                 E
E para a cruz eu vou
                    F#m
Do seu sofrer participar
                    D
Da sua obra vou cantar

          A
Meu salvador
            E
Na cruz mostrou
           F#m
O amor do pai
        D
O justo Deus

A                  
Pela cruz me chamou
F#m
Gentilmente me atraiu
    E
E eu, sem palavras, me aproximo
D
Quebrantado por seu amor

A           
Imerecida vida, 
            E
de graça recebi
            F#m
Por sua cruz 
               D
da morte me livrou

              A
Trouxe-me a vida, 
                E
eu estava condenado
                F#m
Mas agora pela cruz
                D
eu fui reconciliado

A                  
Pela cruz me chamou
F#m
Gentilmente me atraiu
    E
E eu, sem palavras, me aproximo
D
Quebrantado por seu amor

Bm              A/C#   D
Impressionante é o seu amor
Bm              A/C#  D
Me redimiu e me mostrou
            E
O quanto é fiel
        `
    },
    {
        id: 24,
        title: "Maravilhosa Graça",
        author: "Alessandro Reis",
        level: "EASY",
        musicKey: "D",
        youtube: "https://www.youtube.com/watch?v=-LjdN5MhSoI",
        lyric: `
INTRO: D

    D       D/F#  G   D  
Maravilhosa    graça que 
       D/F#    A 
perdido me encontrou
   D    D/F#   G       D 
Estando cego   pude ver, 
    D      A     D  
Cristo me resgatou

[SOLO]

    D     D/F#    G     D 
Quando Tua graça me tocou,
    D    D/F#    A 
do medo me livrou
       D   D/F#   G       D
Quão preci_osa   é pra mim,
    D    A      D  
 a graça do Senhor

    D/F#     G          D/F#
Liberto eu fui de uma prisão
        G/B           A
Meu Salvador me resgatou
       D/F#   G         Bm
Com Mise___ricórdia e amor
          Em A      D
Me dá perdão e redenção

[SOLO]

    D       D/F#  G        D  
Deus prometeu o melhor pra mim 
       D/F#    A 
e nEle esperarei
   D    D/F#   G       D 
Escudo e força Ele será 
    D     A     D
enquanto eu viver

Liberto eu fui...(2x)

[SOLO]

    D    D/F#  G        D  
Se o meu mundo se acabar 
    D/F#    A 
e o sol não mais brilhar
   D        D/F#  G       D 
O Deus que um dia me chamou
   D     A     D
Será pra sempre meu  (4x)

Liberto eu fui… (2x)
[SOLO 2x]

Maravilhosa Graça… (sem instrumentos)
`
    },
    {
        id: 25,
        title: "Luz do Mundo",
        author: "Diante do Trono",
        level: "EASY",
        musicKey: "D",
        youtube: "https://www.youtube.com/watch?v=E1OFVUVJFsM",
        lyric: `
Luz do mundo
Vieste à terra
Pra que eu pudesse Te ver
Tua beleza me leva a adorar-Te
Quero contigo viver

Vim para adorar-Te
Vim para prostrar-me
Vim para dizer que és meu Deus

És totalmente amável
Totalmente digno
Tão maravilhoso
Para mim

Eterno Rei
Exaltado nas alturas
Glorioso nos céus
Humilde vieste à terra que criaste
Por amor, pobre se fez

Vim para adorar-Te
Vim para prostrar-me
Vim para dizer que és meu Deus

És totalmente amável
Totalmente digno
Tão maravilhoso
Para mim

Eu nunca saberei o preço
Dos meus pecados lá na cruz
`},
{
    id: 26,
    title: "Renova-me",
    author: "Aline Barros",
    level: "MEDIUM",
    musicKey: "C",
    youtube: "https://www.youtube.com/watch?v=TzX83Xva9zI",
    lyric: `
Renova-me, Senhor Jesus
Já não quero ser igual
Renova-me, Senhor Jesus
Põe em mim Teu coração

Porque tudo que há dentro de mim
Necessita ser mudado, Senhor
Porque tudo que há dentro do meu coração
Necessita mais de Ti`
},
{
    id: 27,
    title: "Solta o cabo da nau",
    author: "Corinhos Evangélicos",
    level: "EASY",
    musicKey: "C",
    youtube: "https://www.youtube.com/watch?v=TzX83Xva9zI",
    lyric: `
SOLTA O CABO DA NAU
TOMA OS REMOS NAS MÃOS
E NAVEGA COM FÉ EM JESUS
E ENTÃO TU VERÁS, QUE BONANÇA SE FAZ
POIS COM ELE SEGURO SERÁS

1. OH, POR QUE DUVIDAR SOBRE AS ONDAS DO MAR
QUANDO CRISTO CAMINHO ABRIU?
QUANDO FORÇADO ÉS CONTRA AS ONDAS LUTAR
SEU AMOR A TI, QUER REVELAR

2. TREVAS VÊM TE ASSUSTAR
TEMPESTADES NO MAR
DA MONTANHA, O MESTRE TE VÊ
E NA TRIBULAÇÃO, ELE VEM SOCORRER
SUA MÃO BEM TE PODE SUSTER

3. QUANDO PEDES MAIS FÉ, ELE OUVE, OH CRÊ
MESMO SENDO EM TRIBULAÇÃO
QUANDO A MÃO DE PODER, O TEU EGO TIRAR
SOBRE AS ONDAS PODERÁS ANDAR
`},
{
    id: 28,
    title: "Se eu me humilhar",
    author: "Discopraise",
    level: "EASY",
    musicKey: "C",
    youtube: "https://www.youtube.com/watch?v=XEa2jjT9SyQ",
    lyric: `
1. NESTE LUGAR TU ÉS REAL
VOU ME ENTREGAR TOTALMENTE
O TEU TOQUE ABRIU OS OLHOS DO MEU CORAÇÃO
EU POSSO ENXERGAR E, ENTENDER...

SE EU ME HUMILHAR
DIANTE DO TEU ALTAR
E SACRIFICAR AQUILO QUE ME CUSTAR
TU INCLINARÁS OS TEUS OUVIDOS AO MEU CLAMOR

2. MAIS VALE UM DIA NO CENTRO DO TEU QUERER
QUE TODA A VIDA SEM JAMAIS TE CONHECER
TU ÉS MINHA FONTE
MINHA COLHEITA
MINHA HERANÇA!

3. A MINHA FONTE É O TEU AMOR!
MINHA COLHEITA É O TEU AMOR!
MINHA HERANÇA É O TEU AMOR!
`
},
{
    id: 29,
    title: "Vencendo vem Jesus",
    author: "John William Steffe e Julia Ward Howe (1819-1910)",
    level: "EASY",
    musicKey: "G",
    youtube: "https://www.youtube.com/watch?v=t3oQz391_4E",
    lyric: `
Já refulge a glória eterna 
de Jesus, o Rei dos reis
Breve os reinos deste mundo 
seguirão as Suas leis
Os sinais da Sua vinda 
mais se mostram cada vez
Vencendo vem Jesus

Glória, glória, aleluia
Glória, glória, aleluia
Glória, glória, aleluia
Vencendo vem Jesus

O clarim que chama os crentes
à batalha já soou
Cristo, à frente do Seu povo,
multidões já conquistou
O inimigo, em retirada, 
seu furor já demonstrou
Vencendo vem Jesus

Glória, glória, aleluia
Glória, glória, aleluia
Glória, glória, aleluia
Vencendo vem Jesus

Eis que, em glória refulgente,
sobre as nuvens descerá
E as nações e os reis da Terra
com poder governará
Com poder e santidade,
toda a Terra regerá
Vencendo vem Jesus

Glória, glória, aleluia
Glória, glória, aleluia
Glória, glória, aleluia
Vencendo vem Jesus

E, por fim entronizado, 
as nações irá julgar
Todos, grandes e pequenos, 
o Juiz hão de encarar
E os remidos, triunfantes, 
lá no Céu irão cantar
Venceu o Rei Jesus

Glória, glória, aleluia
Glória, glória, aleluia
Glória, glória, aleluia
Venceu o Rei Jesus
`
},
{
    id: 30,
    title: "Plena paz e santo gozo",
    author: "Adriano Nobbre",
    level: "EASY",
    musicKey: "E",
    youtube: "https://www.youtube.com/watch?v=kaGY3hn5v3w",
    lyric: `
1. Plena paz e santo gozo
Tenho em ti, ó meu Jesus!
Pois eu creio 
em Tua morte sobre a cruz
No Senhor só confiando
Neste mundo viverei
Entoando aleluias ao meu Rei!

R. Oh! Glória ao meu Jesus!
Pois é digno de louvor
É meu Rei, meu bom Pastor
E meu Senhor
Como os anjos, que O louvam
Eu também O louvarei
Entoando aleluias ao meu Rei

2. O amor de Jesus Cristo
É mui grande para mim
Pois Sua graça
me encheu de amor sem fim
Meu Jesus foi para a glória
Mas um dia eu O verei
Entoando aleluias ao meu Rei!

3. Este mundo não deseja
Tão bondoso Salvador
Não sabendo 
agradecer Seu grande amor
Eu, porém, estou gozando
Do favor da Sua lei
Entoando aleluias ao meu Rei!

4. Quando o povo israelita
Com Jesus se consertar
Dando glória ao Seu nome, 
sem cessar
Nesse tempo, céu e terra
Hão de ser a mesma grei
Entoando aleluias ao meu Rei!
`
},
{
    id: 31,
    title: "Quão bondozo amigo é Cristo",
    author: "Joseph M. Scriven / R. H. Moreton",
    level: "EASY",
    musicKey: "C",
    youtube: "https://www.youtube.com/watch?v=INKo-2tV11g",
    lyric: `
Quão bondoso amigo é Cristo!
Carregou com a nossa dor
E nos manda que levemos
Os cuidados ao Senhor

Falta ao coração dorido
Gozo, paz, consolação?
Isso é porque não levamos
Tudo a Deus em oração

Tu estás fraco e carregado
De cuidados e temor?
A Jesus, refúgio eterno
Vai com fé teu mal expor!

Teus amigos te desprezam?
Conta-lhe isso em oração
E com Seu amor tão terno
Paz terás no coração

Cristo é verdadeiro amigo
Disto provas nos mostrou
Quando pra levar consigo
O culpado encarnou

Derramou Seu sangue puro
Nossa mancha pra lavar
Gozo em vida e no futuro
NEle podemos alcançar 
`
},
{
    id: 32,
    title: "Exilado Da linda pátria",
    author: "Justus Henry Nelson",
    level: "EASY",
    musicKey: "G",
    youtube: "https://www.youtube.com/watch?v=ySN1gknzYJE",
    lyric: `
Da linda pátria estou bem longe
Cansado estou
Eu tenho de Jesus saudade
Oh, quando é que eu vou?

Passarinhos, belas flores
Querem me encantar
São vãos terrestres esplendores
Mas contemplo o meu lar

Jesus me deu a Sua promessa
Me vem buscar
Meu coração está com pressa
Eu quero já voar

Meus pecados foram muitos
Mui culpado sou
Porém, Seu sangue põe-me limpo
Eu para pátria vou

Qual filho de seu lar saudoso
Eu quero ir
Qual passarinho para o ninho
Pra os braços Seus fugir

É fiel, Sua vinda é certa
Quando? Eu não sei
Mas Ele manda estar alerta
Do exílio voltarei

Sua vinda aguardo eu cantando
Meu lar no céu
Seus passos hei de ouvir soando
Além do escuro véu

Passarinhos, belas flores
Querem me encantar
São vãos terrestres esplendores
Mas contemplo o meu lar
`
},
{
    id: 33,
    title: "Nossa esperança é sua vinda",
    author: "Almeida Sobrinho",
    level: "EASY",
    musicKey: "E",
    youtube: "https://www.youtube.com/watch?v=BNmJyhnP138",
    lyric: `
1. Jesus, sim, vem do céu, 
em glória Ele vem!
Ecoa a nova pelo mundo além
Oh, esperança que a Sua Igreja tem
Dai glória a Deus, 
Jesus em breve vem!

R. Nossa esperança é Sua vinda
O Rei dos reis vem nos buscar
Nós aguardamos, Jesus, ainda
Té a luz da manhã raiar
Nossa esperança é Sua vinda
O Rei dos reis vem nos buscar
Nós aguardamos, Jesus, ainda
Té a luz da manhã raiar

2. Jesus, sim, vem, 
os mortos esperando estão
O gran momento da ressurreição
E do sepulcro 
em breve se levantarão!
Dai glória a Deus, 
Jesus em breve vem!

3. Jesus, sim, vem 
do céu cercado de esplendor
Aniquilando 
a corrupção e a dor
Quebrando os laços 
do astuto usurpador
Dai glória a Deus, 
Jesus em breve vem!

4. Jesus, sim, vem, 
completamente restaurar
O mundo que 
se arruína sem parar
Sim, todas as coisas 
vem depressa transformar
Dai glória a Deus, 
Jesus em breve vem!

5. Jesus, sim, vem, 
e sempiterna adoração
Daremos nós ao Rei de coração
Ao grande autor 
da nossa eterna salvação
Dai glória a Deus, 
Jesus em breve vem!
`
},
{
    id: 34,
    title: "Guarda o contato",
    author: "Paulo Leivas Macalão",
    level: "EASY",
    musicKey: "G",
    youtube: "https://www.youtube.com/watch?v=j3pGE90KaAo",
    lyric: `
1. Queres, neste mundo, 
ser um vencedor?
Queres tu cantar 
nas lutas e na dor?
Queres ser alegre, 
qual bom lutador?
Guarda o contato 
com teu Salvador

R. Guarda o contato 
com teu Salvador
E a nuvem do mal 
não te cobrirá
Pela senda alegre, 
tu caminharás
Indo em contato 
com teu Salvador

2. Neste mundo, 
vivem muitos a penar
Cujos corações 
transbordam de pesar
Dá-lhes a mensagem 
de amor sem par
Com Deus o contato 
deves tu guardar

3. Queres tu, com Deus,
a comunhão obter?
Sua glória em ti 
sempre permanecer?
Que o mundo possa 
Cristo em ti ver
Guarda o contato 
com o supremo Ser

4. Deixa que o Espírito 
implante em teu ser
O amor de Cristo, 
divinal prazer
Queres, neste mundo, 
todo o mal vencer?
Guarda o contato 
e terás poder
`
},
{
    id: 35,
    title: "Os guerreiros se preparam",
    author: "Paulo Leivas Macalão",
    level: "EASY",
    musicKey: "F",
    youtube: "https://www.youtube.com/watch?v=4TyeeKGzFHo",
    lyric: `
1. Os guerreiros se preparam
para a grande luta
É Jesus, o Capitão, 
que avante os levará
A milícia dos remidos 
marcha impoluta
Certa que vitória 
alcançará

R. Eu quero estar com Cristo
Onde a luta se travar
No lance imprevisto
Na frente m'encontrar
Até que O possa ver na glória
Se alegrando da vitória
Onde Deus vai me coroar

2. Eis os batalhões de Cristo
prosseguindo avante
Não os vê com que valor
combatem contra o mal?
Podes tu ficar dormindo, 
mesmo vacilante
Quando atacam outros 
a Belial?

3. Dá-te pressa, não vaciles, 
hoje Deus te chama
Para vires pelejar 
ao lado do Senhor
Entra na batalha onde 
mais o fogo inflama
E peleja contra 
o vil tentador

4. A peleja é tremenda, 
torna-se renhida
Mas são poucos 
os soldados para batalhar
Ó vem libertar 
as pobres almas oprimidas
De quem furioso, 
as quer tragar

Eu quero estar com Cristo
Onde a luta se travar
No lance imprevisto
Na frente m'encontrar
Até que O possa ver na glória
Se alegrando da vitória
Onde Deus vai me coroar
`
},
{
    id: 36,
    title: "Santo para Sempre",
    author: "",
    level: "EASY",
    musicKey: "",
    youtube: "https://youtu.be/ijNCc7ICCck?si=cMNowLmdw4Wx4YBN",
    lyric: ``
},
{
    id: 37,
    title: "Vem me buscar",
    author: "",
    level: "EASY",
    musicKey: "Dm",
    youtube: "https://youtu.be/t6Pd8gXIASU?si=qz4OKpactNXLF7jx",
    lyric: ``
},
{
    id: 38,
    title: "Grande é o Senhor",
    author: "",
    level: "EASY",
    musicKey: "D",
    youtube: "https://youtu.be/Eu6pr3N-UXE?si=tjQxs2Q5e8Y87qmr",
    lyric: ``
},
{
    id: 39,
    title: "Colossenses e suas linhas de amor",
    author: "",
    level: "EASY",
    musicKey: "",
    youtube: "https://youtu.be/MeX0yHMs9Nk?si=X-IHrryOOBfpP3uF",
    lyric: ``
},
{
    id: 40,
    title: "Louve",
    author: "",
    level: "EASY",
    musicKey: "",
    youtube: "https://youtu.be/sEQyi3tVrIM?si=W1wO7dIdwEUr6HIr",
    lyric: ``
},
{
    id: 41,
    title: "Eu te louvarei meu bom Jesus",
    author: "Ronaldo Bezerra",
    level: "EASY",
    musicKey: "D",
    youtube: "https://www.youtube.com/watch?v=_xVuItEPVQE",
    lyric: `
És Tu única razão 
da minha adoração ó Jesus
És Tu única esperança 
que anelo ter ó Jesus
Confiei em ti fui ajudado, 
tua salvação tem me alegrado
Hoje há gozo em meu coração 
com meu canto te louvarei

Eu te louvarei, te glorificarei
Eu te louvarei meu bom Jesus

Em todo tempo te louvarei,
em todo tempo te adorarei
`
},
{
    id: 42,
    title: "Ele é exaltado",
    author: "Adhemar de Campos",
    level: "EASY",
    musicKey: "E",
    youtube: "https://www.youtube.com/watch?v=22c5Flzd8Iw",
    lyric: `
Ele é exaltado 
o Rei é exaltado nos céus
Eu o louvarei

Ele é exaltado 
pra sempre exaltado
O Seu nome louvarei

Ele é o Senhor
Sua verdade vai sempre reinar
Terra e céus
Glorificam Seu Santo nome
Ele é exaltado
O Rei é exaltado nos céus 
`
},
{
    id: 43,
    title: "A Ele a glória",
    author: "Gabriela Rocha",
    level: "EASY",
    musicKey: "D",
    youtube: "",
    lyric: `
1. Porque Dele e por Ele
Para Ele são todas as coisas
Porque Dele e por Ele
Para Ele são todas as coisas

R. A Ele a Glória
A Ele a Glória
A Ele a Glória
Pra sempre, amém

2. Quão profundas riquezas
O saber e o conhecer de Deus
Quão insondáveis
Seus juízos e os Seus caminhos
    `
},
{
    id: 44,
    title: "Bondade de Deus",
    author: "Isaías Saad",
    level: "EASY",
    musicKey: "A",
    youtube: "",
    lyric: `
1. Te amo, Deus, 
tua graça nunca falha
Todos os dias 
eu estou em tuas mãos
Desde quando me levanto 
até eu me deitar
Eu cantarei da bondade de Deus

R. És fiel em todo tempo
Em todo tempo tu és tão, tão bom
Com todo fôlego que tenho
Eu cantarei 
da bondade de Deus

2. Tua doce voz 
que me guia em meio ao fogo
Na escuridão, 
tua presença me conforta
Eu sei que és meu pai, 
que amigo és
E eu vivo na bondade de Deus

Tua bondade me seguirá,
me seguirá Senhor
    `,
},
{
    id: 45,
    title: "Ousado Amor",
    author: "Isaías Saad",
    level: "EASY",
    musicKey: "C",
    youtube: "",
    lyric: `
1. Antes de eu falar
Tu cantavas sobre mim
Tu tens sido tão, tão bom pra mim

2. Antes de eu respirar
Sopraste tua vida em mim
Tu tens sido tão, tão bom pra mim

R. Oh, impressionante, infinito
E ousado amor de Deus
Oh, que deixa as noventa e nove
Só pra me encontrar
Não posso comprá-lo, nem merecê-lo
Mesmo assim se entregou
Oh, impressionante, infinito
E ousado amor de Deus

3. Inimigo eu fui
Mas teu amor lutou por mim
Tu tens sido tão, tão bom pra mim

4. Não tinha valor
Mas tudo pagou por mim
Tu tens sido tão, tão bom pra mim

5. Traz luz para as sombras
Escala montanhas
Pra me encontrar
Derruba muralhas
Destrói as mentiras
Pra me encontrar
    `
},
];
