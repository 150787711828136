import "./Header.css"
import louvorelogo from "../../assets/louvore-logo.png"

export default function Header(){
    return (
        <header>
            <img alt="Louvore Logo" src={louvorelogo} />
            <h1>Louvore</h1>
            <p>
                Auxílio para adoração em cultos.
            </p>
        </header>
    );
}