import MusicListElement from "../MusicListElement/MusicListElement";
import MusicLyric from "../MusicLyric/MusicLyric"; 
import Header from "../Header/Header";
import { compareString } from "../../resources/common/sorting";
import { useState } from "react";
import { useParams } from "react-router-dom/dist";
import { EVENTS_DATA } from "../../resources/events_data";
import { MUSIC_DATA } from "../../resources/music_data";

function getMusicsByEventId(eventId){
    if (!eventId){
        return [];
    }

    let event = EVENTS_DATA.find((event) => event.eventId == eventId);
    let eventMusicIds = event.musics.map((music) => music.id);
    let musics = MUSIC_DATA.filter((music) => eventMusicIds.includes(music.id));

    // Adjust key in event if not empty
    musics.map((music) => {
      let eventMusic = event.musics.find((eventMusic) => eventMusic.id == music.id);
      if (eventMusic.musicKey != ""){
        music.musicKey = eventMusic.musicKey;
      }
    });

    return musics;
}

function getEventDetails(eventId){
    let event = EVENTS_DATA.find((event) => event.eventId == eventId);
    return `${event.date.toLocaleDateString()} - ${event.name}`;
}

export default function MusicListPerEvent(){

  const [selectedMusicTitle, setSelectedMusic] = useState(null);
  const params = useParams();

  function handleSelect(selectedMusicTitle){
    setSelectedMusic(selectedMusicTitle);
  }

  let musicList = <p>Music List.</p>
  let musicLyric = null;
  let music_data = getMusicsByEventId(params.eventId);

  if (music_data.length == 0){
    musicList = <h3>Nenhuma música selecionada.</h3>
  }
  else if(!selectedMusicTitle) {
    musicList = (
      <>
      <h1>{getEventDetails(params.eventId)}</h1>
      <section id="music-list-element">
        {music_data.map((musicItem) => (
            <MusicListElement
              key={musicItem.title}
              {...musicItem}
              onSelect={() => handleSelect(musicItem.title)} />
          ))}
      </section>
      </>
    );
  }
  else {
    let selectedMusic = music_data.filter((musicItem => musicItem.title === selectedMusicTitle))[0];
    musicList = null;
    musicLyric = (
      <MusicLyric 
        {...selectedMusic} 
        onBack={() => handleSelect(null)}/>
    )
  }

    return (
        <div>
            <main>
                {musicList}
                {musicLyric}
            </main>
        </div>
    );

}