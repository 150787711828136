import Header from "../../components/Header/Header";
import EventListElement from "../../components/EventListElement/EventListElement";
import { EVENTS_DATA } from "../../resources/events_data"
import { useNavigate } from "react-router-dom/dist";

const eventState = ['date-box-gone', 'date-box-rightnext', 'date-box-next'];

function processEventsData(){
    let EVENTS_DATA_SORTED = EVENTS_DATA.sort((a, b) =>  a.date - b.date);

    const today = new Date();
    let foundNext = false;
    for (let i=EVENTS_DATA_SORTED.length-1; i>=0; i--){
        EVENTS_DATA_SORTED[i].state = eventState[2];

        if (today - EVENTS_DATA_SORTED[i].date >= 0 && !foundNext){
            foundNext = true;
            EVENTS_DATA_SORTED[i].state = eventState[1];
            if (i+1 < EVENTS_DATA_SORTED.length){
                EVENTS_DATA_SORTED[i+1].state = eventState[2];
            }
        }
        else if (foundNext) {
            EVENTS_DATA_SORTED[i].state = eventState[0];
        }
    }

    return EVENTS_DATA_SORTED;
}


export default function EventList(){
    const navigate = useNavigate();

    function handleEventSelect(eventId){
        let url = `/musics/${eventId}`;
        navigate(url);
    }

    let EVENTS_DATA_SORTED = processEventsData();

    let eventList = (
        EVENTS_DATA_SORTED.map((eventItem) => (
            <EventListElement 
                key={eventItem.eventId}
                {...eventItem}
                onSelect={() => handleEventSelect(eventItem.eventId)} />
        )));


    return (
        <div>
            <main>
                {eventList}
            </main>
        </div>
    );
}