import { Outlet, Link } from "react-router-dom";
import './Root.css'
import Header from "../components/Header/Header";

function RootLayout(){
    return (
        <>
            <ul>
                <li><Link to='/'>Eventos</Link></li>
                <li><Link to='/musics'>Músicas</Link></li>
                <li><Link to='/about'>Sobre</Link></li>
            </ul>
            <Header />
            <Outlet />
        </>
    );
}

export default RootLayout;