import Header from "../../components/Header/Header";
import MusicList from "../../components/MusicList/MusicList";
import { MUSIC_DATA } from "../../resources/music_data";

export default function MusicPage(){
    return (
    <div>
      <main>
        <MusicList music_data={MUSIC_DATA} />
      </main>
    </div>
        );
}