import MusicListElement from "../MusicListElement/MusicListElement";
import MusicLyric from "../MusicLyric/MusicLyric"; 
import { compareString } from "../../resources/common/sorting";
import { useState } from "react";

export default function MusicList({music_data}){

  const [selectedMusicTitle, setSelectedMusic] = useState(null);

  function handleSelect(selectedMusicTitle){
    setSelectedMusic(selectedMusicTitle);
  }

  let musicList = <p>Music List.</p>
  let musicLyric = null;
  let SORTED_MUSIC_DATA = music_data.sort((a, b) => compareString(a.title, b.title));

  if(!selectedMusicTitle) {
    musicList = (
      <section id="music-list-element">
        {SORTED_MUSIC_DATA.map((musicItem) => (
            <MusicListElement
              key={musicItem.title}
              {...musicItem}
              onSelect={() => handleSelect(musicItem.title)} />
          ))}
      </section>
    );
  }
  else {
    let selectedMusic = SORTED_MUSIC_DATA.filter((musicItem => musicItem.title === selectedMusicTitle))[0];
    musicList = null;
    musicLyric = (
      <MusicLyric 
        {...selectedMusic} 
        onBack={() => handleSelect(null)}/>
    )
  }

  return (
    <>
      {musicList}
      {musicLyric}
    </>
  );

}