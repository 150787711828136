import "./EventListElement.css"
import { getDayOfWeek, daysBetweenTwoDates, DAY_OF_WEEK_PT } from "../../resources/common/date";

export default function EventListElement({ date, name, musics, leader, back, state, onSelect }) {
  return (
    <li className="list-item" onClick={onSelect}>
      <div className={state}>{date.toLocaleDateString()} - {getDayOfWeek(date, DAY_OF_WEEK_PT)} </div>
      <div className="content-box">
        <h3>{name}</h3>
        <p><b>Músicas:</b> {musics.length}</p>
        <p><b>Ministro:</b> {leader}</p>
        <p><b>Back:</b> {back}</p>
      </div>
    </li>
  );
}