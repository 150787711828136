import "./MusicListElement.css"

export default function MusicListElement({ title, author, level, musicKey, onSelect }) {
    return (
      <li onClick={onSelect}>
        <h3>{title}</h3>
        <p>{author}</p>
        <div id="info">
          <p id="level">{level}</p>
          <p id="musicKey">{musicKey}</p>
        </div>
      </li>
    );
  }