
export const DAY_OF_WEEK_PT = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
const MILLI_TO_DAYS_FACTOR = (1000 * 60 * 60 * 24);

export function dateDMYtoDate(dateDMY){
    const dateArray = dateDMY.split("/");
    let date = new Date(dateArray[2], dateArray[1]-1, dateArray[0]);
    return date;
}

export function getDayOfWeek(date, dayOfWeekStr){
    let dayOfWeek = date.getDay();
   
    if (dayOfWeek >=0 && dayOfWeek <=6){
        return dayOfWeekStr[dayOfWeek];
    }
    else{
        return "Date Error!";
    }
}

export function daysBetweenTwoDates(date1, date2){
    const time_difference = date2.getTime() - date1.getTime();  
    let days_difference = time_difference / MILLI_TO_DAYS_FACTOR ;  

    return days_difference;
}