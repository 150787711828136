
export const EVENTS_DATA = [
    {
        eventId: 1,
        date: new Date("08/18/2024"),
        name: "Culto Domingo",
        musics: [
            {
                id: 10,
                musicKey: "D"
            },
            {
                id: 11,
                musicKey: "E"
            },
            {
                id: 12,
                musicKey: "B"
            },
            {
                id: 13,
                musicKey: "A"
            },
            {
                id: 14,
                musicKey: "E"
            }
        ],
        leader: "Eduardo",
        back: "Maria, Neia, Ju",
        state: ""
    },
    {
        eventId: 2,
        date: new Date("08/22/2024"),
        name: "Culto Quinta",
        musics: [
            {
                id: 15,
                musicKey: ""
            },
            {
                id: 16,
                musicKey: ""
            },
            {
                id: 17,
                musicKey: ""
            },
        ],
        leader: "Eduardo",
        back: "Andreia, Márcio, Maria",
        state: ""
    },
    {
        eventId: 3,
        date: new Date("08/25/2024"),
        name: "Culto Domingo",
        musics: [
            {
                id: 18,
                musicKey: ""
            },
            {
                id: 19,
                musicKey: ""
            },
            {
                id: 20,
                musicKey: ""
            },            {
                id: 21,
                musicKey: ""
            },            {
                id: 22,
                musicKey: ""
            },
        ],
        leader: "Juliana",
        back: "Marcio, Néia, Maria",
        state: ""
    },
    {
        eventId: 4,
        date: new Date("08/29/2024"),
        name: "Culto Quinta",
        musics: [
            {
                id: 7,
                musicKey: ""
            },
            {
                id: 23,
                musicKey: ""
            },
            {
                id: 24,
                musicKey: ""
            },
        ],
        leader: "Eduardo",
        back: "Andreia, Marcio, Maria",
        state: ""
    },
    {
        eventId: 5,
        date: new Date("09/01/2024"),
        name: "Culto 01/09 - Domingo",
        leader: "Eduardo",
        back: "Maria, Juliana, Neia",
        state: "",
        musics: [
            {
                id: 27,
                musicKey: "",
            },
            {
                id:25,
                musicKey: "",
            },
            {
                id:28,
                musicKey:"",
            },
            {
                id:8,
                musicKey: "",
            },
            {
                id:26,
                musicKey: "",
            },
        ]
    },
    {
        eventId: 6,
        date: new Date("09/05/2024"),
        name: "Culto 05/09 - Quinta",
        leader: "Eduardo",
        back: "Andreia, Marcio, Maria",
        state: "",
        musics: [
            {
                id: 41,
                musicKey: ""
            },
            {
                id: 42,
                musicKey: ""
            },
            {
                id: 2,
                musicKey: ""
            }
        ]
    },
    {
        eventId: 7,
        date: new Date("09/06/2024"),
        name: "Vigília",
        leader: "Toda igreja",
        back: "Toda igreja",
        state: "",
        musics: [
            {
               id: 43,
               musicKey: "" 
            },
            {
               id: 35,
               musicKey: "" 
            },
            {
               id: 44,
               musicKey: "" 
            },
            {
               id: 45,
               musicKey: "" 
            },
            {
               id: 27,
               musicKey: "" 
            },
            {
               id: 13,
               musicKey: "" 
            },
            {
               id: 29,
               musicKey: "" 
            },
            {
               id: 30,
               musicKey: "" 
            },
            {
               id: 31,
               musicKey: "" 
            },
            {
               id: 32,
               musicKey: "" 
            },
            {
               id: 33,
               musicKey: "" 
            },
            {
               id: 34,
               musicKey: "" 
            },
            {
               id: 35,
               musicKey: "" 
            },
        ]
    },
    {
        eventId: 8,
        date: new Date("09/08/2024"),
        name: "Culto Santa Ceia - Domingo",
        leader: "Juliana",
        back: "Neia, Maria, Marcio",
        state: "",
        musics: []
    },
    {
        eventId: 9,
        date: new Date("09/12/2024"),
        name: "Culto Campanha - Quinta",
        leader: "Eduardo",
        back: "Andreia, Marcio, Maria",
        state: "",
        musics: []
    },
    {
        eventId: 10,
        date: new Date("09/15/2024"),
        name: "Culto Adoração - Domingo",
        leader: "Juliana",
        back: "Neia, Maria, Marcio",
        state: "",
        musics: []
    },
    {
        eventId: 11,
        date: new Date("09/19/2024"),
        name: "Culto Campanha - Quinta",
        leader: "Eduardo",
        back: "Andreia, Marcio, Maria",
        state: "",
        musics: []
    },
    {
        eventId: 12,
        date: new Date("09/22/2024"),
        name: "Culto Adoração - Domingo",
        leader: "Eduardo",
        back: "Marcio, Juliana, Neia",
        state: "",
        musics: []
    },
    {
        eventId: 13,
        date: new Date("09/26/2024"),
        name: "Culto Campanha - Quinta",
        leader: "Eduardo",
        back: "Andreia, Marcio, Maria",
        state: "",
        musics: []
    },
    {
        eventId: 14,
        date: new Date("09/29/2024"),
        name: "Culto Adoração - Domingo",
        leader: "Juliana",
        back: "Neia, Maria, Marcio",
        state: "",
        musics: []
    },
];