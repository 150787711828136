import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MusicPage from './pages/MusicPage/MusicPage';
import AboutPage from "./pages/AboutPage/AboutPage";
import EventList from './pages/EventList/EventList';
import MusicListPerEvent from './components/MusicList/MusicListPerEvent';
import RootLayout from './pages/Root';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { path: '/', element: <EventList /> },
      { path: '/musics', element: <MusicPage /> },
      { path: '/musics/:eventId', element: <MusicListPerEvent /> },
      { path: '/about', element: <AboutPage /> }
    ],
  },
])

function App() {
    return <RouterProvider router={router} />;
}

export default App;
